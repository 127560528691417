import axios from 'axios';
import router from '@/router';
import store from '@/store'

let API = axios.create({
    baseURL: '/api/',
    // baseURL: '/api/',
    timeout: 25000,
});
API.interceptors.request.use(
    async config => {
        var token;
        var role;
        await getUserToken().then(element => { if (element) { token = element.jwt; role = element.role } });
        if (router.currentRoute.path.split('/')[1] != "admin" && role == "admin") {
            await getMagicToken().then(element => {
                if (element) { token = element.jwt; role = element.role; }
            });
        }
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
            config.headers['Content-Type'] = 'application / json';
        }
        return config;
    },
    error => {
        return Promise.reject(error)
    })
API.interceptors.response.use(
    response => {
        store.commit(UPDATE_CONNECTION_ERROR);
        return response;

    },
    error => {
        if (error.code == "ECONNABORTED" || error.code == "ERR_NETWORK")
            store.commit(UPDATE_CONNECTION_ERROR, true)
        else
        {
            store.commit(UPDATE_CONNECTION_ERROR);
        }
        return Promise.reject(error)

    }
)
export default API;