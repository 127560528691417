import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@fortawesome/fontawesome-free/css/all.css'
import ru from 'vuetify/lib/locale/ru'
Vue.use(Vuetify)
export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 800 // This is equivalent to a value of 960
  },
  icons: {
    iconfont: 'mdi' || 'fa'
  },
  theme: {
    themes: {
      light: {
        primary: '#202442',
      },
    },
  }, lang: {
    locales: { ru },
    current: 'ru',
  },
})
