<template>
    <section>
        <v-dialog ref="dialog" v-bind="$attrs" v-on="$listeners"
            :content-class="startDialog ? 'dialog_start' : endDialog ? 'dialog_end' : ''"
            :persistent="$attrs.fullscreen == '' || report || $attrs.persistent" v-model="show_value">
            <!--  <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" /> 
        </template> -->
            <v-card v-if="report">
                <v-card-title></v-card-title>
                <v-card-text>
                    <div v-if="!error">
                        <sweet-success />
                        <div class="text-h5 text-center">Сохранено</div>
                    </div>
                    <div v-else>
                        <sweet-error />
                        <div class="position-relative" style="top:-20px">
                            <div class="text-h4 text-center">{{ title_error }}</div>
                            <div class="text-center" style="font-size: 20px">
                                {{ error_text }}
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <slot v-else name="MainModal" />
        </v-dialog>
        <v-dialog ref="dialog_preloader" v-model="show_preloader" width="500" :persistent="true">
            <v-card v-if="!report">
                <v-card-text>
                    {{ loading_text }}
                    <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
            <v-card v-else>
                <v-card-title></v-card-title>
                <v-card-text>
                    <div v-if="!error">
                        <sweet-success />
                        <div class="text-h5 text-center">Сохранено</div>
                    </div>
                    <div v-else>
                        <sweet-error />
                        <div class="position-relative" style="top:-20px">
                            <div class="text-h4 text-center">{{ title_error }}</div>
                            <div class="text-center" style="font-size: 20px">
                                {{ error_text }}
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </section>
</template>
<script>
import {
    VDialog, VCardText, VCard,
} from 'vuetify/lib'
import Vue from 'vue';
export default {
    inheritAttrs: false, // optional
    data() {
        return {
            show_preloader: false,
            report: true,
            error: false,
            title_error: "Ошибка",
            show_value: false,
            default_error_text: "Ошибка сервера",
            error_text: this.default_error_text,

        }
    },
    props:
    {
        loading_text: {
            type: String,
            default: "Загрузка"
        },
        havePreloader: {
            type: Boolean,
            default: false,
        },
        startDialog: {
            type: Boolean,
            default: false
        },
        endDialog: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        ClosePreloader() {
            this.show_preloader = false;
            Vue.nextTick(() => this.show_value = true)
        },
        Success() {
            this.error = false;
            this.report = true;
            if (!this.show_value) this.show_value = true;
            setTimeout(() => {
                this.show_value = false;
            }, 2000);
        },
        Error(err) {
            this.title_error = "Ошибка"
            var action="";
            if (err == undefined) this.error_text = "Ошибка программы";
            else if (typeof err === 'string') { this.error_text = err; }
            else if (typeof err == 'object' && err != null) {
                if (err.hasOwnProperty("action")) {
                    action = err.action;
                    err = err.err;
                }
                if (err?.response) {
                    var status = err.response.status;
                    var data = err.response.data;
                    if (status) {
                        if (data instanceof Object) {
                            if (data.hasOwnProperty('title')) this.title_error = data.title;
                            if (data.hasOwnProperty('detail')) this.error_text = data.detail;
                        }
                        else if (typeof data === 'string' && data != "") {
                            this.error_text = data;
                        }
                        else {
                            this.error_text = err;
                        }
                    }
                    else { this.error_text = err; }
                }
                else if (err?.code == "ERR_NETWORK") { this.error_text = "Сервер недоступен" }
                else { this.error_text = err; }
            }
            this.error = true;
            this.report = true;
           // if (this.show_preloader) { this.show_preloader = false }
            this.$nextTick(() => {
                if(!this.show_preloader)
                    this.show_value = true;
                if (action == "no_hide") {
                    setTimeout(() => { this.report = false; this.error = false }, 2000)

                }
                else {
                    setTimeout(() => {this.show_value = false; this.show_preloader = false }, 2000)
                }
            });
        },
        Hide() {
            this.show_preloader = false;
            this.show_value = false;
        },
        Show() {
            this.error_text = this.default_error_text
            this.error = false;
            this.report = false;
            if (this.havePreloader) {
                this.show_value = false;
                this.show_preloader = true;
            }
            else {
                this.show_preloader = false;
                this.show_value = true;
            }
        }
    },
}
</script>
<style scoped>
.fadeln-enter-active,
.fadeln-leave-active {
    transition: opacity .5s;
}

.fadeln-enter,
.fadeln-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

>>>.dialog_end {
    align-self: flex-end;
}

>>>.dialog_start {
    align-self: flex-start;
}
</style>