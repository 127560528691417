/* eslint-disable */
<template>
    <div class="main-wrapper">
        <!-- Шапка-->
        <header-panel :ml="mlValue" :hasMiniVariant="hasMiniVariant"> </header-panel>

        <!-- Шапка (конец) -->
        <!-- Боковая навигация -->
        <sidenav-panel :mini-size="miniSize" @hasMiniVariantChange="hasMiniVariantChange"
            @hasOpenState="hasOpenStateChange"></sidenav-panel>
        <!-- Боковая навигация -->
        <!-- Content Body Start  -->
        <preloader />
        <v-main
            :style="{ 'padding-left': hasMiniVariant ? 3 + 'px' : 10 + 'px', 'padding-right': hasMiniVariant ? 3 + 'px' : 10 + 'px', 'padding-top': hasMiniVariant ? 50 + 'px' : 82 + 'px', 'margin-left': mlValue, }"
            style="padding-top: 50px; padding-bottom: 50px;">
            <v-container class="main-container">
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </v-container>
        </v-main>
        <!-- Footer Section Start -->
        <v-footer padless app outlined>
            <div style="width: 100%;" :style="{ 'margin-left': mlValue }" class="text-center py-1">
                <div class="text-muted">&copy; <a href="https://amsy.ru/index.html">ООО "АМС" Калуга</a>&nbsp;
                    2009 - {{ year_now }} </div>
            </div>
        </v-footer>
        <!-- Footer Section End -->
    </div>
</template>
<script>
import {
    eventBus
} from '@/main.js'
import {
    mapGetters
} from "vuex";
import moment from 'moment';
import { VContainer } from 'vuetify/lib';
import { VLayout } from 'vuetify/lib';
export default {
    name: 'HomeView',
    data() {
        return {
            miniSize: 56,
            size: 256,
            debug_socket: false,
            socket: null,
            tryopencount: 0,
            checksocketTimeout: null,
            socketLastMsgTime: moment(), // время приема последних данных с сокета
            hasMiniVariant: false,
            hasOpenState: false,
        };
    },
    async mounted() {
        eventBus.$on('New_message', async (message) => {
            let imei = message.Imei;
            let appealDate = message.AppealDate;
            let device_id = message.Device_id;
            let msg = "";
            let file_length;
            let offset;
            let user_name;
            let user_id;
            let user_role;
            let software_version;
            await getUserToken().then(async el => {
                if (el != null && el != undefined) {
                    user_id = el.userID;
                    user_role = el.role
                    user_name = el.userFullName
                }
                else return;
            });
            if (user_role == "admin") {
                if (this.$route.path.split('/')[1] == "user") {
                    await getMagicToken().then(async resp => {
                        user_id = resp.userID;
                        user_name = resp.userFullName
                    })
                }
            }
            if (message.UserID == user_id) {
                var device = this.AllDevices.find(el => el.id == device_id);
                if (device != undefined && message.MessageType != "DeviceJoined") {
                    //  await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })
                }
                /* if (message.MessageType === "DeviceJoined") {
                      eventBus.$emit('DeviceJoined', device_id)
                      msg = (
                          <div>
                              <div style="color:white; line-height: 1.3;" class="m-0" >
                           
                                  IMEI: {imei}<br>
                                  </br>
                                  Дата/время: {moment(appealDate).format('DD.MM.YYYY HH:mm')}
                              </div>
                              <span style=" font-weight: 700;">Устройство присоединилось к WebConfig</span>
                          </div>
                      )
                      this.$toast.info(msg);
                  }*/
                if (message.MessageType === "UpdateFirmwareAcceptedResponse") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    eventBus.$emit('UpdateFirmwareAcceptedResponse', device_id)
                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Команда обновления принята устройством</span>
                        </div>
                    )
                    this.$toast.info(msg);
                }
                if (message.MessageType === "UpdateParametersAcceptedResponse") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    eventBus.$emit('UpdateParametersAcceptedResponse', device_id)
                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Команда обновления параметров принята устройством</span>
                        </div>
                    )
                    this.$toast.info(msg);
                }
                if (message.MessageType === "UpdateCalibrationAcceptedResponse") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    eventBus.$emit('UpdateCalibrationResponse', device_id)
                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Команда обновления тарировок принята устройством</span>
                        </div>
                    )
                    this.$toast.info(msg);
                }
                if (message.MessageType === "SendFileLengthResponse") {
                    file_length = message.File_length;
                    offset = message.Offset;
                    software_version = message.SoftwareVersion;
                    eventBus.$emit('SendFileLengthResponse', {
                        id: device_id,
                        file_length: file_length,
                        offset: offset,
                        software_version: software_version
                    })
                }
                if (message.MessageType === "UpdateFirmwareOk") {
                    eventBus.$emit('UpdateFirmwareOKResponse', device_id)
                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Обновление ПО завершено</span>
                        </div>
                    )
                    this.$toast.success(msg);
                }
                if (message.MessageType === "UpdateParametersOk") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    eventBus.$emit('UpdateParametersOkResponse', device_id)
                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Получены параметры</span>
                        </div>
                    )
                    this.$toast.success(msg);
                }
                if (message.MessageType === "UpdateCalibrationOk") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    eventBus.$emit('UpdateCalibrationOkResponse', device_id)
                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Обновление тарировок завершено</span>
                        </div>
                    )
                    this.$toast.success(msg);
                }
                if (message.MessageType === "UpdateCalibrationCancel") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Команда обновления тарировок отменена</span>
                        </div>
                    )
                    this.$toast.error(msg);
                }
                if (message.MessageType === "UpdateParametersCancel") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Команда обновления параметров отменена</span>
                        </div>
                    )
                    this.$toast.error(msg);
                }
                if (message.MessageType === "UpdateFirmwareCancel") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Команда обновления прошивки отменена</span>
                        </div>
                    )
                    this.$toast.error(msg);
                }

                if (message.MessageType === "SetCANFileAcceptedResponce") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    eventBus.$emit('SetCANFileAcceptedResponce', device_id)
                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Команда установки CAN-файла принята устройством</span>
                        </div>
                    )
                    this.$toast.info(msg);
                }
                if (message.MessageType === "SetCANFileCancel") {
                    await this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })

                    msg = (
                        <div>
                            <div style="color:white; line-height: 1.3;" class="m-0" >
                                IMEI: {imei} </div>
                            <span style=" font-weight: 700;">Команда установки CAN-файла отменена</span>
                        </div>
                    )
                    this.$toast.error(msg);
                }
            }
        })
    },
    methods: {
        hasOpenStateChange(new_value) {
            this.hasOpenState = new_value;
        },
        hasMiniVariantChange(new_value) {
            this.hasMiniVariant = new_value;
        },
        async initializesocket() {
            var vm = this;
            var user_role
            this.tryopencount++;
            var tokenID;
            var location = window.location;
            let connection_string = process.env.NODE_ENV === 'development' ? 'http://localhost:5002' : window.origin;
            if (location.protocol == "http:") {
                connection_string = connection_string.replace("http", "ws") + "/ws"
            }
            if (location.protocol == "https:") {
                connection_string = connection_string.replace("https", "wss") + "/ws"
            }
            //UpdateSystemStatus( CG_DICTIONARY.UI.statusbar_connecting, true );
            this.socket = new WebSocket(connection_string);
            this.socket.onopen = async function (e) {
                await getUserToken().then(async resp => {
                    if (resp) {
                        user_role = resp.role
                        tokenID = resp.tokenID;
                    }
                });
                if (user_role == "admin") {
                    if (vm.$route.path.split('/')[1] == "user") {
                        await getMagicToken().then(async resp => {
                            if (resp) {
                                tokenID = resp.tokenID;
                            }
                        })
                    }
                }
                vm.socket.send(tokenID); // "5162f761-90e8-4cfc-aa9c-5b732d2ac8d3"
                vm.tryopencount = 0;
                vm.checkSocketAlive();
            };
            this.socket.onmessage = function (event) {
                vm.socketLastMsgTime = moment();
                if (vm.debug_socket == true)
                    console.log(`[message] Data received: ${event.data} <- server`);
                var message = JSON.parse(event.data);
                if (message != undefined && message != '') {
                    eventBus.$emit('New_message', message);
                }
            };
            this.socket.onclose = function (event) {
                clearTimeout(vm.checksocketTimeout); // отключаем проверку сокета
                var msg;
                if (event.wasClean)
                    msg = `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`;
                else
                    msg = '[close] Connection died';
                if (vm.debug_socket == true)
                    console.log('' + msg);
                if (vm.tryopencount > 2) {
                    console.log(`[trying connect count] ${vm.tryopencount}`);
                } else {
                    setTimeout(vm.initializesocket, 1000 * 5); // попытка переподключения
                }
            };
            this.socket.onerror = function (error) {
                if (vm.debug_socket == true)
                    console.log(`[error] ${error.message}`);
            };
        },
        checkSocketAlive() {
            try {
                this.socket.send("");
                if (this.debug_socket)
                    console.log('[check socket]')
                this.checksocketTimeout = setTimeout(this.checkSocketAlive, 1000 * 5);
            } catch { }
        },
    },
    computed: {
        mlValue() {
            return this.hasOpenState ? this.hasMiniVariant ? this.miniSize + 'px' : this.size + 'px' : 0 + 'px'
        },

        ...mapGetters(['AllDevices']),
        year_now() {
            return moment().year();
        },
    },
    beforeDestroy() {
        if (this.socket != null) {
            this.socket.close();
        }
        eventBus.$off['New_message']
    },
    async created() {

        await this.initializesocket()
    },
    components: { VLayout, VContainer }
}
</script>