<template>
    <transition name="fade-preloader" v-if="GetFirstShowStatus && ShowPreloader">
        <div class="preloader">
            <div class="preloader_animation"></div>
            <p class="preloader_text">Загрузка данных. Пожалуйста, подождите....</p>
        </div>
    </transition>
</template>
<script>
import {
    mapGetters,
} from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'GetFirstShowStatus',
            'UsersGetAreLoaded',
            'UsersGetHaveError',
            'DevicesGetAreLoaded',
            'DevicesGetHaveError',
            'DeviceTypesGetAreLoaded',
            'DeviceTypesGetHaveError',
            "FirmwaresGetHaveError",
            "FirmwaresGetAreLoaded",
            'LoggerGetAreLoaded',
            'LoggerGetHaveError'
        ]),
        ShowPreloader() {
            switch (this.$route.path) {
                case "/admin/users":
                    if ((this.UsersGetHaveError || this.UsersGetAreLoaded) && (this.DeviceTypesGetAreLoaded || this.DeviceTypesGetHaveError) && (this.DevicesGetAreLoaded || this.DevicesGetHaveError)) {
                        this.$store.commit(CLEAR_FIRST_SHOW_STATUS);
                        return false;
                    } else {
                        return true;
                    }
                case "/admin/logger":
                    if ((this.LoggerGetHaveError || this.LoggerGetAreLoaded) && (this.UsersGetAreLoaded || this.UsersGetHaveError)  && (this.DevicesGetHaveError || this.DevicesGetAreLoaded)) {
                        this.$store.commit(CLEAR_FIRST_SHOW_STATUS);
                        return false;
                    } else {
                        return true;
                    }
                case "/admin/devices":
                    if ((this.UsersGetHaveError || this.UsersGetAreLoaded) && (this.DevicesGetHaveError || this.DevicesGetAreLoaded) && (this.DeviceTypesGetHaveError || this.DeviceTypesGetAreLoaded)) {
                        this.$store.commit(CLEAR_FIRST_SHOW_STATUS);
                        return false;
                    } else {
                        return true;
                    }
                case "/admin/firmwares":
                    if ((this.DeviceTypesGetHaveError || this.DeviceTypesGetAreLoaded) && (this.FirmwaresGetHaveError || this.FirmwaresGetAreLoaded) && (this.DevicesGetHaveError || this.DevicesGetAreLoaded)) {
                        this.$store.commit(CLEAR_FIRST_SHOW_STATUS);
                        return false;
                    } else {
                        return true;
                    }
                case "/user/devices":
                    if ((this.DevicesGetHaveError || this.DevicesGetAreLoaded) && (this.DeviceTypesGetAreLoaded || this.DeviceTypesGetHaveError)) {
                        this.$store.commit(CLEAR_FIRST_SHOW_STATUS);
                        return false;
                    } else {
                        return true;
                    }
                case "/user/logger":
                    if ((this.LoggerGetAreLoaded || this.LoggerGetHaveError) && (this.DevicesGetHaveError || this.DevicesGetAreLoaded)) {
                        this.$store.commit(CLEAR_FIRST_SHOW_STATUS);
                        return false;
                    } else {
                        return true;
                    }
                default:
                    return false;
            }
        }
    }
}
</script>
<style
>
.fade-preloader-enter-active,
.fade-preloader-leave-active {
    transition: opacity 0.5s ease;
}
.fade-preloader-enter-from,
.fade-preloader-leave-to {
    opacity: 0;
}
/* PRELOADER_STYLE*/
.preloader {
    position: absolute;
    z-index: 9999999;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 99.9vh;
    background-color: var(--v-main-color);
}
.preloader p {
    font-size: 1.25rem;
    font-weight: bold;
}
.preloader_animation {
    position: relative;
    display: block;
    width: 150px;
    height: 150px;
    margin: 30px auto;
    border: 3px solid transparent;
    border-top-color: #414670;
    border-radius: 50%;
    animation: preloader_animation-spin 2s linear infinite;
}
.preloader_animation:before {
    content: "";
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border: 3px solid transparent;
    border-top-color: #1a3d5f;
    border-radius: 50%;
    animation: preloader_animation-spin 3s linear infinite;
}
.preloader_text {
    text-align: center;
    color: #202442;
}
.preloader_animation:after {
    content: "";
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border: 3px solid transparent;
    border-top-color: #202442;
    border-radius: 50%;
    animation: preloader_animation-spin 1.5s linear infinite;
}
@keyframes preloader_animation-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* PRELOADER_STYLE*/
</style>